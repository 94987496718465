<template>
  <div class="activetitle">
    <div><span class="title-bar"></span>{{$store.state.content_title||title}}</div>
    <div class="title-right" v-if="userInfo.type === 1">
      <div class="nameTime">
        <div class="market">
          <img src="../assets/header.png" style="background-color: #66b1ff"/>
          <span v-if="venuList.length==0">{{ userInfo.marketName }}</span>
          <el-dropdown v-if="venuList.length>0" trigger="click" @command="handleCommandVenu">
            <span style="fontSize: 14px;cursor: pointer;">
              {{ userInfo.marketName }}
            </span>
            <el-dropdown-menu slot="dropdown" class="dropdown-wrapper">
              <el-dropdown-item v-for="item in venuList" :key="item.marketId" :command="item" :style="{color:userInfo.marketName==item.marketName ? '#66b1ff':'',backgroundColor: userInfo.marketName==item.marketName ? '#ecf5ff':''}">{{item.marketName}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="operatorItem">
        <i class="el-icon-s-tools"></i>
        <el-dropdown trigger="click" @command="handleCommand">
          <span style="cursor: pointer">设置</span>
          <el-dropdown-menu slot="dropdown" class="dropdown-wrapper">
            <el-dropdown-item command="1">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {initRoute} from "@/router/initRoute";
export default {
  name: 'd-title',
  data () {
    return {
      venuList:[],
      title: '',
      userInfo: null,
    }
  },
  computed: {
  },
  components: {
  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // 获取场馆列表
    this.$api.sysapi.listEnable().then(res=>{
    if(res.code==200){
      this.venuList = res.data.list
    }
    })
  },
  mounted () {
    this.title = this.$route.name
  },
  methods: {
    handleCommandVenu(command){
      let _this = this
      if(command.marketName == this.userInfo.marketName){
        return
      }
      // 用户切换场馆
      this.$api.sysapi.changeMarket({marketId: command.marketId}).then(res=>{
      if(res.code==200){
          this.userInfo.marketName = command.marketName
          // 获取用户权限
          this.$api.sysapi.tokenInfo().then(({ code, data, msg })=>{
            if(code==200){
              if(!data.sysModule){
                return this.$message.error('当前用户没有访问权限，请联系管理员')
              }
              var exp = new Date()
              var Days = 7
              exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
              document.cookie = `DK_TOKEN=${data.token
                };expires=${exp.toGMTString()}`
              localStorage.setItem('sysModule', JSON.stringify(data.sysModule))
              let permission = data.sysModule.child.filter(item => item.id === 2 /*'后台权限'*/);
              let pathName
              if(permission&&permission.length){   //  有后台权限
                const _menuList = initRoute(data.sysModule)
                delete data.sysModule
                localStorage.setItem('userInfo', JSON.stringify(data))
                localStorage.setItem('menuList', JSON.stringify(_menuList))
                let my_menuList = _menuList.filter(item=>{
                  return item.name
                })
                _this.$router.replace({
                  path: my_menuList[0].path
                })
                setTimeout(()=>{
                  location.reload()
                },300)
              }else{   //  无权限
                _this.$message.error('当前用户没有任何权限，请联系管理员分配权限')
              }
            }
          })
        }
      })
    },
    handleCommand(command) {
      if (+command === 1) {
        this.$confirm("此操作将会注销账号, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              document.cookie = 'DK_TOKEN=""';
              this.$router.push("/login");
              window.localStorage.clear()
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消注销",
              });
            });
      }
    },
  },
  watch: {
    $route (to, from) {
      if (to.path === '/checkModule/check/reportMarket/check') {
        this.title = '上报销售/审核'
        return;
      }  else if (to.path === '/checkModule/check/papers/check') {
        this.title = '证件办理/审核'
        return;
      } else if (to.path === '/checkModule/check/realname/check') {
        this.title = '实名认证/审核'
        return;
      } else if (to.path === '/checkModule/check/shopowner/check') {
        this.title = '店长申请/审核'
        return;
      } else if (to.path === '/shopModule/shop/mainShop/authority') {
        this.title = '设置权限'
        return;
      } else if (to.path === '/systemModule/auth/role/create') {
        this.title = '新建角色'
        return;
      } else if (to.path === '/systemModule/auth/user/create') {
        this.title = '新建用户'
        return;
      } else if (to.path === '/systemModule/auth/user/authority') {
        this.title = '设置权限'
        return;
      } else if (to.path === '/systemModule/auth/role/compile') {
        this.title = '编辑角色'
        return;
      }
      this.title = to.name
    }
  }
}
</script>
<style lang="scss" scoped>
.activetitle {
  position: fixed;
  z-index: 9;
  // margin-top: 80px;
  width: 100%;
  height: 50px;
  background-color: #fff;
  line-height: 50px;
  padding-left: 270px;
  .title-bar {
    position: absolute;
    top: 0;
    left: 260px;
    bottom: 0;
    margin: auto 0;
    display: inline-block;
    height: 20px;
    width: 5px;
    background-color: $subjectColor;
  }
}
.title-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 300px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.nameTime {
  margin-right: 20px;
  .market {
    img {
      width: 20px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

.operatorItem {
    i {
      font-size: 18px;
      line-height: 50px;
    }

    span {
      margin-left: 5px;
      font-size: 18px;
    }
  }
  .dropdown-wrapper {
  padding: 5px;

  .el-dropdown-menu__item {
    font-size: 14px;
    margin: 5px 0;
  }
}
</style>
