<template>
  <div class="header">
    <div class="lago">
      <div class="logoImg">
        <!-- 演示 -->
        <!-- <img src="../assets/logo.png" /> -->

        <!-- 武汉 -->
        <!-- <img src="../assets/production/logo-wuhan.png" /> -->

        <!-- 欢乐颂 -->
        <img src="../assets/production/logo.png" />

        <!-- 影之宝 -->
        <!-- <img src="../assets/production/logo-jianguo.png" /> -->
      </div>
    </div>
    <div class="nav">
      <div class="NavMenu">
        <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
        >
          <template  v-for="(item, index) in navList">
            <el-menu-item
                v-if="item.meta && item.meta.isShow"
                :key="index"
                :index="item.path"
                class="header-menu"
            >
              <router-link :to="item.path">
                {{item.meta.title}}
              </router-link>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="nameTime">
        <div>您好，{{ userInfo.name }}&nbsp;&nbsp;&nbsp;{{ currentDate }}</div>
        <div class="market">
          <img src="../assets/header.png"/>
          <span v-if="venuList.length==0">{{ userInfo.marketName }}</span>

          <el-dropdown v-if="venuList.length>0" trigger="click" @command="handleCommandVenu">
            <span style="color: #fff;fontSize: 14px;cursor: pointer;">
              {{ userInfo.marketName }}
            </span>
            <el-dropdown-menu slot="dropdown" class="dropdown-wrapper">
              <el-dropdown-item v-for="item in venuList" :key="item.marketName" :command="item" :style="{color:userInfo.marketName==item.marketName ? '#66b1ff':'',backgroundColor: userInfo.marketName==item.marketName ? '#ecf5ff':''}">{{item.marketName}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="operatorItem">
      <i class="el-icon-s-tools"></i>
      <el-dropdown trigger="click" @command="handleCommand">
        <span style="cursor: pointer">设置</span>
        <el-dropdown-menu slot="dropdown" class="dropdown-wrapper">
          <el-dropdown-item command="2">修改密码</el-dropdown-item>
          <el-dropdown-item command="1">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <template v-if="dialogVisible">
      <el-dialog
          title="修改密码"
          :visible.sync="dialogVisible"
          :append-to-body="true"
          width="30%"
      >
        <el-form
            label-position="right"
            label-width="80px"
            class="password-form"
            :model="form"
            :rules="passwordRules"
            ref="passwordForm"
        >
          <el-form-item label="手机号">
            {{ phone }}
          </el-form-item>
          <el-form-item label="当前密码" prop="password">
            <el-input v-model="form.password" show-password></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input v-model="form.newPassword" show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="form.confirmPassword" show-password></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closePassword">取 消</el-button>
          <el-button type="primary" @click="changePassword">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import {validateUserPwd} from "@/utils/validator";
import {initRoute} from "@/router/initRoute";

export default {
  name: "d-header",
  data() {
    const required = (rule, val, cb) => {
      if (val === "") {
        cb("请输入当前密码");
      } else {
        cb();
      }
    };
    const validatePass = (rule, val, cb) => {
      if (val === "") {
        cb(new Error("请输入密码"));
      } else {
        if (this.form.confirmPassword !== "") {
          this.$refs.passwordForm.validateField("confirmPassword");
        }
        cb();
      }
    };
    const validatePass2 = (rule, val, cb) => {
      if (val === "") {
        cb(new Error("请再次输入密码"));
      } else if (this.form.newPassword !== val) {
        cb(new Error("两次输入密码不一致!"));
      } else {
        cb();
      }
    };
    return {
      venuList:[],
      navList: [],
      name: "",
      userInfo: null,
      dialogVisible: false, //修改密码框
      form: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      phone: JSON.parse(localStorage.getItem("userInfo")).mobile,
      passwordRules: {
        password: [
          {validator: required, trigger: "blur"},
          {validator: validateUserPwd, trigger: "blur"},
        ],
        newPassword: [
          {validator: validatePass, trigger: "blur"},
          {validator: validateUserPwd, trigger: "blur"},
        ],
        confirmPassword: [
          {validator: validatePass2, trigger: "blur"},
          {validator: validateUserPwd, trigger: "blur"},
        ],
      },
    };
  },
  computed: {
    activeIndex() {
      let path = this.$route.path ? "/" + this.$route.path.split("/")[1] : "";
      return path;
    },
  },
  created() {
    this.setTime();
    const menuList = JSON.parse(localStorage.getItem("menuList"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.navList = menuList

    // 获取场馆列表
    this.$api.sysapi.listEnable().then(res=>{
      if(res.code==200){
        this.venuList = res.data.list
      }
    })
  },
  methods: {
    handleCommandVenu(command){
      let _this = this
      if(command.marketName == this.userInfo.marketName){
        return
      }
      // 用户切换场馆
      this.$api.sysapi.changeMarket({marketId: command.marketId}).then(res=>{
      if(res.code==200){
          this.userInfo.marketName = command.marketName
          // 获取用户权限
          this.$api.sysapi.tokenInfo().then(({ code, data, msg })=>{
            if(code==200){
              // localStorage.setItem("userInfo",JSON.stringify(res2.data))
              if(!data.sysModule){
                return this.$message.error('当前用户没有访问权限，请联系管理员')
              }
              var exp = new Date()
              var Days = 7
              exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
              document.cookie = `DK_TOKEN=${data.token
                };expires=${exp.toGMTString()}`
              localStorage.setItem('sysModule', JSON.stringify(data.sysModule))
              let permission = data.sysModule.child.filter(item => item.id === 2 /*'后台权限'*/);
              let pathName
              if(permission&&permission.length){   //  有后台权限
                const _menuList = initRoute(data.sysModule)
                delete data.sysModule
                localStorage.setItem('userInfo', JSON.stringify(data))
                localStorage.setItem('menuList', JSON.stringify(_menuList))
                let my_menuList = _menuList.filter(item=>{
                  return item.name
                })
                // _this.setTime();
                // const menuList = JSON.parse(localStorage.getItem("menuList"));
                // _this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
                // _this.navList = menuList
                _this.$router.replace({
                  path: my_menuList[0].path
                })
                setTimeout(()=>{
                  location.reload()
                },300)
                // pathName = _menuList.filter(e=>e.meta.isShow)[0].path;
                // _this.$router.push({ path: pathName })
              }else{   //  无权限
                _this.$message.error('当前用户没有任何权限，请联系管理员分配权限')
              }
              // location.reload()
            }
          })
        }
      })
    },
    setTime() {
      let date = new Date();
      this.currentDate = `${date.getFullYear()}-${
          date.getMonth() + 1
      }-${date.getDate()}`;
    },
    /**
     * 抽屉按钮选择
     */
    handleCommand(command) {
      if (+command === 1) {
        this.$confirm("此操作将会注销账号, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              document.cookie = 'DK_TOKEN=""';
              this.$router.push("/login");
              localStorage.removeItem("sysPeopleTree");
              // this.deleteSysUser(localStorage.getItem('id'))
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消注销",
              });
            });
      } else if (+command === 2) {
        this.dialogVisible = true;
      }
    },

    deleteSysUser(id) {
      this.$api.sysapi.deleteSysUser(id).then(({code, msg, data}) => {
        if (code === 200) {
          this.$message({
            message: msg,
            type: "success",
          });
          document.cookie = 'DK_TOKEN=""';
          this.$router.push("/login");
        } else {
          this.$message.error(msg);
        }
      });
    },

    closePassword() {
      this.dialogVisible = false;
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },

    changePassword() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.$api.sysapi.changePassword(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.closePassword();
            }
          });
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1200px;
  background-color: $subjectColor;
  height: 80px;
  display: flex;
  color: $menuText;
  box-shadow: 0 4px 8px #e2e2e2;
  z-index: 9;
  font-size: 18 rpx;

  .lago {
    width: 250px;
    min-width: 210px;
    max-width: 210px;
    display: flex;

    .logoImg {
      display: inline-block;
      width: 100px;
      margin: auto;

      img {
        width: 100%;
      }
    }
  }

  .nav {
    flex: 1;

    .NavMenu {
      font-size: 18px;
      width: 65%;

      .el-menu-demo {
        background-color: transparent;
        border: none;
        height: 100%;
        text-align: center;
        display: flex;

        .is-active {
          border: 0;
          background-color: $menuHover;
        }

        .el-menu-item {
          height: 100%;
          width: 100%;
          max-width: 150px;
          color: #fff;
          line-height: 80px;
          margin: 0;

          a {
            width: 100%;
            max-width: 150px;
            color: #fff;
            line-height: 80px;
            margin: 0;
            height: 80px;
            display: inline-block;
          }
        }

        .el-menu-item:hover {
          background-color: $menuHover;
        }

        .is-active:hover {
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .nameTime {
      position: absolute;
      right: 250px;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .market {
        margin-top: 5px;

        img {
          width: 20px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }

  .operatorItem {
    position: absolute;
    right: 0px;
    width: 200px;
    margin: 0 10px;

    i {
      font-size: 18px;
      line-height: 80px;
    }

    span {
      margin-left: 5px;
      color: #fff;
      font-size: 18px;
    }
  }
}

.dropdown-wrapper {
  padding: 10px;

  .el-dropdown-menu__item {
    font-size: 14px;
    margin: 10px 0;
  }
}

.password-form {
  .el-form-item {
    margin-bottom: 28px;
  }
}
</style>
