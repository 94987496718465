<template>
  <div class="el-menu-vertical">
    <div class="logo-image">
      <img src="../../assets/AIOS.png" />
      <h3>AIOS</h3>
    </div>
    <el-menu class="el-menus" :default-active="activeIndex" router :unique-opened="true">
      <template v-for="(item, index) in menuList">
        <template v-if="!(item.path.includes('dynamicForm'))">
          <template v-if="item.meta && item.meta.isShow">
            <el-submenu v-if="item.children" :index="item.path" :key="item.path">
              <template slot="title">
                <span>{{ item.meta.title }}</span>
              </template>
              <template v-for="menus in item.children">
                <template v-if="menus.meta && menus.meta.isShow">
                  <el-submenu v-if="menus.children" :index="menus.path" :key="menus.path">
                    <template slot="title">
                      <span>{{ menus.meta.title }}</span>
                    </template>
                    <template v-for="menu in menus.children">
                      <el-menu-item v-if="menu.meta && menu.meta.isShow" :key="menu.path + ''"
                        :index="menu.path">{{
                          menu.meta.title }}</el-menu-item>
                    </template>
                  </el-submenu>
                  <el-menu-item :index="menus.path" v-else>
                    {{ menus.meta.title }}
                  </el-menu-item>
                </template>
              </template>
            </el-submenu>
            <el-menu-item :index="menus.path" v-else>
              {{ menus.meta.title }}
            </el-menu-item>
          </template>
        </template>
        <template v-else-if="item.name === '动态表单'">
          <el-submenu :index="item.path">
            <template slot="title">
              <span class="title-text">{{ item.name }}</span>
            </template>
            <el-submenu v-for="menus in item.dynamicFormMenu" :key="menus.id" :index="menus.id + ''">
              <template slot="title">
                <span class="title-text">{{ menus.groupName }}</span>
              </template>
              <el-menu-item v-for="menu in menus.basics" :key="menu.id" @click="select"
                :index="`/businessModule/dynamicForm/${menus.id}/${menu.id}/list`">
                <span class="title-text">
                  {{ menu.name }}
                </span>
              </el-menu-item>
            </el-submenu>
          </el-submenu>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import TemplateParser from "../dynamicForm/components/JSONParser/TemplateParser";

export default {
  name: "d-menu",
  components: { TemplateParser },
  data () {
    return {
      dynamicFormMenu: [],
      activeIndex: '',
      menuListTitle: [],
      menuList: []
    };
  },
  inject: ["reload"],
  watch: {
    '$route': {
      immediate: true,
      handler (val, oldVal) {
        this.activeIndex = this.changeRoute();
      }
    }
  },
  created () {
    const menuList = JSON.parse(localStorage.getItem("menuList"));
    this.menuList = menuList
    this.activeIndex = this.changeRoute();
    this.getDynamicMenu();
  },
  methods: {
    select () {
      // this.reload();
    },
    getDynamicMenu () {
      this.$api.dynamicForm.externalAcl().then((res) => {
        if ( res && res.code === 200 ) {
          let {data} = res
          let dynamicFormMenu = {
            path: '/dynamicForm',
            name: '动态表单',
            dynamicFormMenu: [...data]
          }
          this.menuList.splice(1, 0, dynamicFormMenu)
          this.dynamicFormMenu = data;
          localStorage.setItem("dynamicFormMenu", JSON.stringify(data));
        }
      });
    },
    changeRoute () {
      const { id } = this.$router.currentRoute.meta;
      const menuId = this.$router.currentRoute.matched[0].meta.id
      const menuList = JSON.parse(localStorage.getItem("menuList"));
      this.menuListTitle = menuList.find(e => e.meta.id == menuId).children;
      if ([201, 208, 214, 6, 221, 396].includes(id)) {
        return this.menuListTitle[0].children ?
          this.menuListTitle[0].children.filter(e => e.meta && e.meta.isShow)[0].path :
          this.menuListTitle[0].path;
      } else {
        return this.matchedActiveIndex(this.$router.currentRoute)
      }
    },

    matchedActiveIndex (route) {
      if (route.matched.length > 3) {
        return route.matched[2].path
      } else {
        return route.path
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-menu-vertical {
  position: fixed;
  z-index: 99;
  // top: 80px;
  width: 240px;
  font-size: 16px;
  height: 100%;
  // height: calc(100% - 80px);
  background-color: #fff;

  .logo-image {
    position: absolute;
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .el-menus {
    height: calc(100% - 104px);
    overflow-y: auto;
    margin-top: 104px;
  }

  .title-text {
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
